$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; $solved-color: green;

.select-kit {
  &.solved-status-filter {
    min-width: auto;
    margin-right: 0.5em;
    .select-kit-header {
      color: var(--primary-high);
    }
  }
}

.fa.accepted {
  color: $solved-color;
}

.post-controls .extra-buttons {
  // anon text
  .accepted-text {
    white-space: nowrap;
    .d-icon,
    .accepted-label {
      color: $solved-color;
    }
  }

  // logged in button
  .accepted {
    .d-icon,
    .d-button-label {
      color: $solved-color;
    }
  }
}

.post-controls span.accepted-text {
  padding: 8px 10px;
  font-size: $font-up-1;
  span {
    display: inline-block;
    padding: 8px 1px;
  }
  .accepted-label {
    margin-left: 7px;
  }
}

.mobile-view .solved-panel {
  margin-bottom: 15px;
}

.solved-panel {
  .by {
    display: none;
  }

  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 13px;
}

aside.quote .title.title-only {
  padding: 12px;
}

.user-card-metadata-outlet.accepted-answers {
  display: inline-block;
}
